@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add any global styles here */

body, #root {
  background-color: #1C2A34;
  min-height: 100vh;
}

.skill-box {
  transition: all 0.3s ease;
}

.skill-box:hover {
  box-shadow: 0 0 15px rgba(74, 222, 128, 0.5);
  background-color: rgba(74, 222, 128, 0.2);
}
